import React from 'react';
import dataText from '../components/gui/gui-typo/data';
import StandardPage from '../components/page/page-standard';

const pageName = 'bonus-system-page';

const itemsData = [
  {
    title: 'Что такое бонусная система?',
  },
  {
    title: 'Как начисляются бонусы?',
  },
  {
    title: 'Как можно потратить бонусы?',
    items: [
      {
        title: 'Использование бонусов в салоне?',
      },
      {
        title: 'Использование бонусов на сайте?',
      },
    ],
  },

];

itemsData.forEach((item) => {
  if (!item.text) {
    // eslint-disable-next-line no-param-reassign
    item.text = dataText.listItem;
  }

  if (item.items) {
    // eslint-disable-next-line no-shadow
    item.items.forEach((item) => {
      if (!item.text) {
        // eslint-disable-next-line no-param-reassign
        item.text = dataText.listItem;
      }
    });
  }
});

const BonusSystemPage = () => (
  <StandardPage pageName={pageName} title="Бонусная система">

    {/* todo alex create List component */}
    {/* todo alex include List component in gui page */}
    <ol className="list list_num">

      {itemsData.map((item, key) => {
        const { items } = item;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <li className="list__item" key={key}>
            <div className="list__item-title page__title page__title_h2">{item.title}</div>
            <div className="list__item-body page__limited-text">{item.text}</div>

            {items && (
              <ol className="list list_num">

                {/* eslint-disable-next-line no-shadow */}
                {items.map((item, key) => {
                  // eslint-disable-next-line no-shadow
                  const { items } = item;

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <li className="list__item" key={key}>
                      <div className="list__item-title page__title page__title_h2">{item.title}</div>
                      <div className="list__item-body page__limited-text">{item.text}</div>
                      {items && (
                        <ol className="list list_num">
                          {/* eslint-disable-next-line no-shadow */}
                          {itemsData.map((item, key) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li className="list__item" key={key}>
                              <div className="list__item-title page__title page__title_h2">{item.title}</div>
                              <div className="list__item-body page__limited-text">{item.text}</div>
                            </li>
                          ))}
                        </ol>
                      )}
                    </li>
                  );
                })}

              </ol>
            )}
          </li>
        );
      })}

    </ol>

  </StandardPage>
);

export default BonusSystemPage;
